/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */

@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';

.content-box.max-w-1336 {
	padding-left: calc(calc(100vw - 1336px) / 2);
}

app-main-header {
	.max-w-1336 {
		max-width: 1336px;
	}
}

.box-8 {
	.cdk-virtual-scroll-content-wrapper {
		padding-right: calc(calc(100vw - 1336px) / 2);
		padding-top: 32px;
		padding-bottom: 32px;

		> * > ion-grid {
			padding-left: calc(100% / 10);
			padding-right: calc(100% / 10);
		}
	}
}

@media only screen and (max-width: 1336px) {
	.max-w-1336 {
		.h-100 {
			padding-left: 16px;
		}
	}

	app-main-header {
		.max-w-1336 {
			padding-right: 16px;
		}
	}
}

img {
	object-fit: cover;
}

@import './theme/output.css';

.app-import-jobs {
	--width: 296px;
	--box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

	.popover-content {
		border-radius: 4px;
	}
}

.app-import-jobs-input {
	--max-width: 440px;
	--min-width: 440px;
	--max-height: 200px;
	--min-height: 200px;

	background: rgb(0 0 0 / 50%);

	--border-radius: 8px;
}

.applied-application-box {
	--width: 876px;
	--box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	--max-height: 360px;
}

@media (max-width: 478px) {
	.app-import-jobs-input {
		--min-width: calc(100% - 40px);
		--max-width: calc(100% - 40px);
		--max-height: 230px;
		--min-height: 230px;
	}
}

.job-detail-alert {
	--max-height: 197px;
	--min-height: 197px;
	--max-width: 652px;
	--min-width: 652px;

	background: rgb(0 0 0 / 77%);

	--border-radius: 8px;
}

@media (max-width: 651.98px) {
	.job-detail-alert {
		--max-height: 240px;
		--min-height: 240px;
		--max-width: 317px;
		--min-width: 317px;
	}
}

.vetted-tag {
	border-radius: 20px;
	background: linear-gradient(0deg,
			rgba(255, 255, 255, 0.77),
			rgba(255, 255, 255, 0.77)),
		#00daae;
	align-items: center;
	padding: 2px 4px;
	display: flex;
	gap: 3px;
	color: rgba(0, 0, 0, 0.8);
	min-height: fit-content;
}

.note-component {
	--box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	--height: auto;
	--border-radius: 4px;
}

.spinner {
	display: flex;
	justify-content: center;
}
