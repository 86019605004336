.visible {
  visibility: visible
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.float-left {
  float: left
}

.m-16 {
  margin: 16px
}

.my-8 {
  margin-top: 8px;
  margin-bottom: 8px
}

.mx-20 {
  margin-left: 20px;
  margin-right: 20px
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px
}

.my-28 {
  margin-top: 28px;
  margin-bottom: 28px
}

.my-32 {
  margin-top: 32px;
  margin-bottom: 32px
}

.my-10 {
  margin-top: 10px;
  margin-bottom: 10px
}

.mx-16 {
  margin-left: 16px;
  margin-right: 16px
}

.mr-8 {
  margin-right: 8px
}

.mb-32 {
  margin-bottom: 32px
}

.ml-8 {
  margin-left: 8px
}

.mt-32 {
  margin-top: 32px
}

.mt-8 {
  margin-top: 8px
}

.mt-16 {
  margin-top: 16px
}

.mr-4 {
  margin-right: 4px
}

.mb-16 {
  margin-bottom: 16px
}

.ml-auto {
  margin-left: auto
}

.mb-8 {
  margin-bottom: 8px
}

.mb-4 {
  margin-bottom: 4px
}

.\!mr-8 {
  margin-right: 8px !important
}

.mr-2 {
  margin-right: 2px
}

.ml-4 {
  margin-left: 4px
}

.mr-16 {
  margin-right: 16px
}

.mt-20 {
  margin-top: 20px
}

.ml-16 {
  margin-left: 16px
}

.mb-6 {
  margin-bottom: 6px
}

.mb-12 {
  margin-bottom: 12px
}

.ml-12 {
  margin-left: 12px
}

.mt-2 {
  margin-top: 2px
}

.\!mt-32 {
  margin-top: 32px !important
}

.mt-24 {
  margin-top: 24px
}

.\!mr-48 {
  margin-right: 48px !important
}

.mt-12 {
  margin-top: 12px
}

.mt-4 {
  margin-top: 4px
}

.mr-32 {
  margin-right: 32px
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.grid {
  display: grid
}

.inline-grid {
  display: inline-grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.h-full {
  height: 100%
}

.h-20 {
  height: 20px
}

.h-56 {
  height: 56px
}

.h-24 {
  height: 24px
}

.h-10 {
  height: 10px
}

.h-50 {
  height: 50px
}

.w-20 {
  width: 20px
}

.w-24 {
  width: 24px
}

.w-10 {
  width: 10px
}

.w-full {
  width: 100%
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
}

.cursor-pointer {
  cursor: pointer
}

.flex-wrap {
  flex-wrap: wrap
}

.items-center {
  align-items: center
}

.\!items-center {
  align-items: center !important
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.gap-16 {
  gap: 16px
}

.justify-self-end {
  justify-self: end
}

.overflow-y-scroll {
  overflow-y: scroll
}

.rounded-full {
  border-radius: 9999px
}

.rounded-lg {
  border-radius: 0.5rem
}

.rounded-md {
  border-radius: 0.375rem
}

.border {
  border-width: 1px
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity))
}

.p-16 {
  padding: 16px
}

.p-10 {
  padding: 10px
}

.p-32 {
  padding: 32px
}

.p-6 {
  padding: 6px
}

.p-20 {
  padding: 20px
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px
}

.py-8 {
  padding-top: 8px;
  padding-bottom: 8px
}

.px-8 {
  padding-left: 8px;
  padding-right: 8px
}

.py-2 {
  padding-top: 2px;
  padding-bottom: 2px
}

.px-32 {
  padding-left: 32px;
  padding-right: 32px
}

.pr-16 {
  padding-right: 16px
}

.pt-4 {
  padding-top: 4px
}

.pb-16 {
  padding-bottom: 16px
}

.pb-56 {
  padding-bottom: 56px
}

.pl-8 {
  padding-left: 8px
}

.pl-16 {
  padding-left: 16px
}

.pt-16 {
  padding-top: 16px
}

.pl-12 {
  padding-left: 12px
}

.pt-32 {
  padding-top: 32px
}

.pt-8 {
  padding-top: 8px
}

.pb-24 {
  padding-bottom: 24px
}

.pt-24 {
  padding-top: 24px
}

.pb-32 {
  padding-bottom: 32px
}

.pb-8 {
  padding-bottom: 8px
}

.pb-6 {
  padding-bottom: 6px
}

.pb-20 {
  padding-bottom: 20px
}

.pr-8 {
  padding-right: 8px
}

.pr-1 {
  padding-right: 1px
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-bottom {
  vertical-align: bottom
}

.font-sans {
  font-family: Libre Franklin, sans-serif
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.italic {
  font-style: italic
}

.leading-6 {
  line-height: 1.5rem
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity))
}

.text-Blues_Default_Blue {
  --tw-text-opacity: 1;
  color: rgb(85 102 251 / var(--tw-text-opacity))
}

.text-rose-600 {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity))
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity))
}

.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity))
}

.no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}
